<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">KASUS</p>
          <router-link
            v-if="isPSPD"
            :to="{ name: 'aktivitas-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <generic-filter-select
              class="filter2"
              apiPath="/matakuliah/options/"
              field="Mata Kuliah"
              v-model="filterMap.mata_kuliah"
            ></generic-filter-select>
            <verifikasi-select class="filter2" v-model="filterMap.verifikasi"></verifikasi-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>
          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="perawatanList"
            :mobile-cards="false"
          >
            <b-table-column label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="tanggal_masuk" label="Pasien" v-slot="props">
              <span class="tanggal">{{ props.row.tanggal_masuk }}</span>
              <br />
              <router-link :to="{ name: detailNamedURL, params: { id: props.row.id } }">
                {{ props.row.no_rm }}
                <b-icon
                  class="rawat-icon"
                  size="is-small"
                  :icon="getRawatIcon(props.row.jenis_perawatan)"
                />
              </router-link>
              <br />
              {{ props.row.pasien }}
              <!-- <br />
              {{ props.row.lokasi.nama }}-->
            </b-table-column>
            <b-table-column field="aktivitas" label="Aktivitas" v-slot="props">
              <div v-if="props.row.komponen_dict.diagnosis_set.length > 0">
                <b-field grouped group-multiline>
                  D :
                  <komponen-perawatan-tag
                    v-for="ak_diag in props.row.komponen_dict.diagnosis_set"
                    :key="ak_diag.komp_id"
                    :id="props.row.id"
                    :namedUrl="'klinis-aktkomp-detail'"
                    :aktivitas="props.row"
                    :aktKomp="ak_diag"
                    :kompId="ak_diag.komp_id"
                    :jenis="'diagnosis'"
                    :tagColor="ak_diag.user_exists"
                  />
                </b-field>
                <hr v-if="hasNextPerawatanKomp('D', props.row)" />
              </div>

              <div v-if="props.row.komponen_dict.pemeriksaan_set.length > 0">
                <b-field grouped group-multiline>
                  T :
                  <komponen-perawatan-tag
                    v-for="ak_pem in props.row.komponen_dict.pemeriksaan_set"
                    :key="ak_pem.komp_id"
                    :id="props.row.id"
                    :namedUrl="'klinis-aktkomp-detail'"
                    :aktivitas="props.row"
                    :aktKomp="ak_pem"
                    :kompId="ak_pem.komp_id"
                    :jenis="'terapi'"
                    :tagColor="ak_pem.user_exists"
                  />
                </b-field>
                <hr v-if="hasNextPerawatanKomp('P', props.row)" />
              </div>

              <div v-if="props.row.komponen_dict.tatalaksana_set.length > 0">
                <b-field grouped group-multiline>
                  P :
                  <komponen-perawatan-tag
                    v-for="ak_tata in props.row.komponen_dict.tatalaksana_set"
                    :key="ak_tata.komp_id"
                    :id="props.row.id"
                    :namedUrl="'klinis-aktkomp-detail'"
                    :aktivitas="props.row"
                    :aktKomp="ak_tata"
                    :kompId="ak_tata.komp_id"
                    :jenis="'pengendalian dan pencegahan'"
                    :tagColor="ak_tata.user_exists"
                  />
                </b-field>
                <hr v-if="hasNextPerawatanKomp('T', props.row)" />
              </div>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { clone, has } from "lodash";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import { mapGetters } from "vuex";
import PerawatanList from "../models/perawatanList.js";

export default {
  name: "PerawatanList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    VerifikasiSelect: () =>
      import("@/apps/aktivitas/components/VerifikasiSelect.vue"),
    KomponenPerawatanTag: () =>
      import("@/apps/perawatan/components/KomponenPerawatanTag.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.perawatanListMdl = new PerawatanList();
    this.listMdl = this.perawatanListMdl; // bikin alias
    let obv = this.perawatanListMdl.getObservables();
    obv.detailNamedURL = "klinis-detail";
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isPSPD"]),
  },
  methods: {
    hasNextPerawatanKomp(jenisKomp, row) {
      let kompLenArr = [
        row.komponen_dict.diagnosis_set.length,
        row.komponen_dict.pemeriksaan_set.length,
        row.komponen_dict.tatalaksana_set.length,
        row.komponen_dict.keterampilan_set.length,
      ];
      const kompOrder = ["D", "P", "T", "K"];
      const startIdx = kompOrder.indexOf(jenisKomp) + 1;
      return kompLenArr.slice(startIdx).reduce((total, num) => total + num) > 0;
    },
    getRawatIcon(jenis) {
      return jenis == "jalan" ? "walking" : "bed";
    },
  },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (has(this.$route.params, "verifikasi") ||
      has(this.$route.params, "stase")) {
      this.perawatanListMdl.reset();
      this.filterMap.verifikasi = this.$route.params.verifikasi;
      this.filterMap.stase = this.$route.params.stase;
      this.filterMap.lulus = null;
      this.filterMap.cari = "";
      this.filterPrevMap = clone(this.filterMap);
    }
    this.fetchData();
  },
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.tanggal {
  font-size: 0.8rem !important;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.rawat-icon {
  vertical-align: middle;
  padding-bottom: 5px;
}
</style>
